<template>
  <form novalidate @submit.prevent="onSubmit">
    <p>We have sent a verification number to your email. It may take a few minutes to arrive.</p> <p>If you can’t find the email, please check your Junk or Spam folder or <a href="#" tabindex="2" class="link-black" :class="{disabled: state.processing}" @click.prevent="resendCode">Send again</a>.</p>

    <p v-if="$externalResults.form?.global" class="text-danger">
      {{ $externalResults.form?.global }}
    </p>

    <div class="form-group mb-6">
      <input v-model="form.code" class="form-control form-control-modern" :class="{'is-invalid': v$.form.code.$error}" placeholder="Enter verification number..." type="text" tabindex="1">
      <transition name="fadeDown">
        <span v-if="v$.form.code.$error" class="invalid-feedback">{{ v$.form.code.$errors[0].$message }}</span>
      </transition>
    </div>

    <!--    TODO-Max: this should depend on sesssion.subscribed param received from backend. get session API needed -->
    <div v-if="!store.session?.respondent?.subscribed" class="form-group">
      <div class="form-check">
        <input id="mail_subscription" v-model="form.mail_subscription" type="checkbox" class="form-check-input" tabindex="1">
        <label class="form-check-label fs-sm " for="mail_subscription">Send me occasional <strong>nowhere</strong> news</label>
      </div>
    </div>

    <div class="form-controls text-center mt-10 mb-4 d-grid">
      <button type="submit" class="submit-btn btn btn-outline-primary btn-lg" tabindex="1" :disabled="state.processing">
        <span v-if="!state.processing">Continue</span>
        <span v-else>Processing</span>
      </button>
    </div>

    <p class="text-center text-size-sm">By clicking 'Continue' you agree to our<br>
      <a class="link-black" :href="`${CONST.NOWHERE_URL}/legals/tos-applications`" target="_blank">Terms of Service</a>,
      <a class="link-black" :href="`${CONST.NOWHERE_URL}/legals/privacy`" target="_blank">Privacy Policy</a> &
      <a class="link-black" :href="`${CONST.NOWHERE_URL}/legals/eula`" target="_blank">EULA</a>
    </p>

    <transition name="fade">
      <div v-if="state.processing" class="component-loader">
        <img v-svg-inline class="isvg isvg-spinner" src="@root/img/svg/spinner.svg">
      </div>
    </transition>
  </form>
</template>

<script setup>
import { externalServerValidation, required } from "@root/js/vuejs/utils/vuelidate";
import useVuelidate from '@vuelidate/core';
import { onBeforeUnmount, onMounted, reactive, ref } from 'vue';
import {useRouter} from 'vue-router';
import {onBeforeRouteUpdate} from 'vue-router';

import CONST from '../../common/constants';
import { useMainStore } from '../../stores/main.js';

const store = useMainStore();
const router = useRouter();

const props = defineProps({
  phone: String
});

// State
const state = reactive({
    processing: null
  }),
  form = reactive({
    code: "",
    mail_subscription: false
  });

// Hooks
onBeforeRouteUpdate(() => {
  state.processing = false;
});

// Validation
const $externalResults = ref({});
const rules = {
    form: {
      code: {required}
    },
  };

const v$ = useVuelidate(rules, { form }, { $externalResults, $autoDirty: true });

resendCode();

async function resendCode() {
  if(state.processing) return; // invalid or processing form
  state.processing = true;

  try {
    await store.getPasscodeCode();
    state.processing = false;
    $externalResults.value = {};
  } catch(err) {
    if(err.response?.data?.errors){
      $externalResults.value = {
        form: err.response.data.errors
      };
    }
    state.processing = false;
  }
}

async function onSubmit() {
  if(state.processing || !await v$.value.$validate()) return; // invalid or processing form
  state.processing = true;

  try {
    let {data} = await store.verifyPasscodeCode({
      ...form
    });
    window.location.href = data?.data?.redirect_url;
  } catch(err) {
    if(err.response?.data?.errors){
      $externalResults.value = {
        form: err.response.data.errors
      };
    }
    state.processing = false;
  }
}
</script>
