export default Object.freeze({
  API_PATH_ADMIN: '/admin/api',
  API_PATH_GOD: '/god/api',
  API_PATH_BIXE: '/bixe/api',
  DATE_FORMAT: 'dd MMMM yyyy',
  DATE_FORMAT_SHORT: 'dd MMM yyyy',
  DATE_FORMAT_NUMERIC: 'dd-MM-yyyy',
  DATE_FORMAT_ORDINARY: 'do MMMM yyyy',
  SUBSCRIPTION_PRICE: 27,
  TIME_FORMAT: 'HH:mm',
  API_DEBOUNCE_DELAY: 500,
  UI_DEBOUNCE_DELAY: 1000,
  USER_ROLE: {
    ADMIN: "admin",
    OWNER: "owner",
    MEMBER: "member"
  },
  BIXE_TYPE:{
    INDIVIDUAL: 1,
    TEAM: 2,
    INDIVIDUAL_FREE: 3,
    TEAM_FREE: 4
  }
});
