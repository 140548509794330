<template>
  <p v-if="state.success" class="text-success text-center">We have reset your password</p>
  <p v-else>Please enter your new password</p>

  <p v-if="$externalResults.form?.global" class="text-danger" v-html="$externalResults.form?.global" />

  <p v-if="!state.valid" class="text-danger">
    The password reset link you have used is incorrect or has expired. If you need further help, please contact <a class="text-danger" href="mailto:support@now-here.com?subject=Reset%20password%20link%20problem&amp;"><strong>support@now-here.com</strong></a>
  </p>

  <form v-if="!state.success" novalidate @submit.prevent="onSubmit">
    <div class="form-group form-group-modern">
      <input v-model="form.password" class="form-control form-control-modern" :class="{'is-invalid': v$.form.password.$error}" placeholder="New password..." type="password" tabindex="0" autocomplete="new-password">
      <transition name="fadeDown">
        <span v-if="v$.form.password.$error" class="invalid-feedback">{{ v$.form.password.$errors[0].$message }}</span>
      </transition>
    </div>

    <div class="form-group form-group-modern">
      <input v-model="form.password_confirmation" class="form-control form-control-modern" :class="{'is-invalid': v$.form.password_confirmation.$error}" placeholder="Confirm new password..." type="password" tabindex="0" autocomplete="new-password">
      <transition name="fadeDown">
        <span v-if="v$.form.password_confirmation.$error" class="invalid-feedback">{{ v$.form.password_confirmation.$errors[0].$message }}</span>
      </transition>
    </div>

    <div class="form-controls text-center mt-10 mb-4 d-grid">
      <button type="submit" class="submit-btn btn btn-outline-primary btn-lg" tabindex="0" :disabled="state.processing">
        <span v-if="!state.processing">Continue</span>
        <span v-else>Processing <img v-svg-inline class="isvg isvg-spinner" src="@root/img/svg/spinner.svg"></span>
      </button>
    </div>
  </form>

  <div v-else class="form-controls text-center mt-10 mb-4 d-grid">
    <router-link class="btn btn-outline-primary btn-lg" :to="{name: 'login'}" tabindex="3">Continue</router-link>
  </div>

</template>

<script setup>
import { required, sameAs, sameAsPassword, strongPassword } from "@root/js/vuejs/utils/vuelidate";
import useVuelidate from '@vuelidate/core';
import { computed, onBeforeUnmount, onMounted, reactive, ref } from 'vue';
import {useRoute} from 'vue-router';

import { useMainStore } from '../../stores/main.js';

const props = defineProps({
  token: String
});

const route = useRoute();
const store = useMainStore();

const state = reactive({
    loading: null,
    success: null,
    valid: false
  }),
  form = reactive({
    password: "",
    password_confirmation: ""
  });

// Validation
const $externalResults = ref({});
const rules = {
    form: {
      password: {required, strongPassword},
      password_confirmation: {required, sameAsPassword: sameAsPassword(computed(() => form.password))}
    },
  };

const v$ = useVuelidate(rules, { form }, { $externalResults, $autoDirty: true });

fetchInitialData();

async function fetchInitialData(){
  store.loading = true;

  try {
    let {data} = await store.fetchResetPasswordData();
    state.valid = data?.data?.valid;
    store.loading = false;
  } catch (err){
    store.loading = false;
  }
}

async function onSubmit() {
  if(state.processing || !await v$.value.$validate()) return; // invalid or processing form

  state.processing = true;

  try {
    await store.resetPassword({
      token: props.token,
      email: route.query?.email,
      ...form
    });
    state.success = true;
    state.processing = false;
  } catch(err) {
    if(err.response?.data?.errors){
      $externalResults.value = {
        form: err.response.data.errors
      };
    }
    state.processing = false;
  }
}
</script>
