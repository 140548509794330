<template>
  <div class="app-wrapper" :class="[route.meta.pageClass]">
    <div class="page-container">
      <component :is="layout" v-if="!state.loading">
        <router-view />
      </component>
    </div>

    <transition name="fade">
      <div v-if="store.processing || store.loading" class="page-loader page-loader-big ">
        <img v-svg-inline class="isvg isvg-spinner" src="@root/img/svg/spinner.svg">
      </div>
    </transition>

    <div v-if="!hideFooter" class="page-footer fs-xs">© <span class="fw-bold">nowhere</span> group {{ store.getYear }}</div>
  </div>
</template>

<script setup>
import { computed, reactive } from 'vue';
import {RouterView, useRoute} from 'vue-router';

import AuthLayout from './layouts/Auth.vue';
import EmptyLayout from './layouts/Empty.vue';
import { useMainStore } from './stores/main.js';

const layouts = {
  AuthLayout,
  EmptyLayout
};

const route = useRoute()

// Store
const store = useMainStore();

// State
const state = reactive({
    loading: true
  });

// Computed
const layout = computed(() => {
    let layout = route.meta?.layout || "Auth"
    return layouts[`${layout}Layout`]
  }),
  hideFooter = computed(() => route.meta?.hideFooter);

fetchInitialData();

async function fetchInitialData(){
  state.loading = true;
  try {
    await store.fetchSession();
    state.loading = false;
  } catch (err){
    state.loading = false;
  }
}

</script>
