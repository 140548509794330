import { h } from 'vue'
import * as VueRouter from 'vue-router';

import AccountForgot from '../modules/Account/Forgot.vue';
import AccountName from '../modules/Account/Name.vue';
import AccountSwitcher from '../modules/Account/Switcher.vue';
import Login from '../modules/Login/Login.vue';
import PasscodeLogin from '../modules/Login/Passcode.vue';
import NotFound404 from '../modules/Pages/NotFound404.vue';
import PasscodeLogout from '../modules/PasscodeLogout.vue';
import Forgot from '../modules/Password/Forgot.vue';
import Reset from '../modules/Password/Reset.vue';
import Register from '../modules/Register/Register.vue';
import TwoFaCode from '../modules/TwoFa/Code.vue';
import TwoFaSetup from '../modules/TwoFa/Setup.vue';
import TwoFaVerify from '../modules/TwoFa/Verify.vue';
import {useMainStore} from '../stores/main.js';

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory("/auth"),
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
      props: (route) => ({
        expired: route.query?.session_expired === 'true'
      }),
      meta: {
        title: 'Sign In',
      }
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
      meta: {
        title: 'Register',
      }
    },
    {
      path: '/password/forgot',
      name: 'password-forgot',
      component: Forgot,
      meta: {
        title: 'Password Reset',
      }
    },
    {
      path: '/password/reset/:token',
      name: 'password-reset',
      component: Reset,
      props: true,
      meta: {
        title: 'Password Reset',
      }
    },
    {
      path: '/two_fa/setup',
      name: 'two-fa-setup',
      component: TwoFaSetup,
      meta: {
        title: '2FA Setup',
      }
    },
    {
      path: '/two_fa/verify',
      name: 'two-fa-verify',
      component: TwoFaVerify,
      meta: {
        title: '2FA Verify',
      }
    },
    {
      path: '/two_fa/code',
      name: 'two-fa-code',
      component: TwoFaCode,
      props: (route) => ({
        phone: route.query?.phone_ends
      }),
      meta: {
        title: 'Sign In',
      }
    },
    {
      path: '/passcode',
      name: 'passcode',
      component: PasscodeLogin,
      meta: {
        title: 'Sign In',
      }
    },
    {
      path: '/passcode/logout',
      name: 'passcode-logout',
      component: PasscodeLogout,
      meta: {
        title: 'Logged Out',
      }
    },
    {
      path: '/account/switcher',
      name: 'account-switcher',
      component: AccountSwitcher,
      meta: {
        title: 'Account switcher',
      }
    },
    {
      path: '/account/name',
      name: 'account-name',
      component: AccountName,
      meta: {
        title: 'Account name',
      }
    },
    {
      path: '/account/forgot',
      name: 'account-forgot',
      component: AccountForgot,
      meta: {
        title: 'Forgot account name',
      }
    },
    {
      name: "not-found",
      path: '/404',
      component: NotFound404,
      meta: {
        title: 'Not Found',
        layout: 'Empty'
      }
    },
    {
      path: '/:pathMatch(.*)*',
      component: NotFound404,
      meta: {
        title: 'Not Found',
        layout: 'Empty'
      }
    },
  ],
});

router.beforeEach((to, from) => {
  const store = useMainStore();
  store.initialPageLoad = from.name === undefined;

  document.title = `${store.isBixe ? 'bixe' : 'nowhere'} : ${to.meta?.title ?? 'Auth'}`;
});

export default router;
