// tracking params
var _paq = window._paq = window._paq || [];
var piwikSiteId = document.querySelector("#piwikSiteId")?.dataset?.id;
if(piwikSiteId){
  _paq.push(['alwaysUseSendBeacon']);
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  _paq.push(['setTrackerUrl', "//an.now-here.com/piwik.php"]);
  _paq.push(['setSiteId', piwikSiteId]);
}
