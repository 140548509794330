import errorResponseHandler from '@root/js/vuejs/services/errorResponseHandler';
import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.defaults.headers.common['Accept'] = 'application/json';
// axios.defaults.headers.common['Origin'] = window.location.origin;
// apply interceptor on response
axios.interceptors.response.use(
  (response) => response,
  errorResponseHandler
);
