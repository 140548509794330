import axios from 'axios';
import Cookies from 'js-cookie';
import { defineStore } from 'pinia';
import {useRouter} from 'vue-router';

import CONST from '../common/constants';

const router = useRouter();

export const useMainStore = defineStore('auth', {
  state: () => ({
    appData: null, // contains data that used by app and need to be available on page load(before any user initiated events)
    twoFa: null,
    loading: false,
    initialPageLoad: null,
    session: null,
    user: null
  }),
  getters: {
    isElectronApp() {
      return !!window.ipcApi?.send;
    },
    isBixe(){
      return this.session?.is_bixe;
    },
    isMicroskills(){
      return this.session?.is_microskills;
    },
    getYear(){
      return (new Date()).getFullYear();
    },
  },
  actions: {
    /** Fetch session data. Expected data
     * bixe: Boolean,
     * microskills: Boolean,
     * respondent.subscribed: Boolean,
     * @returns {Promise<void>}
     */
    async fetchSession() {
      this.session = JSON.parse(document.getElementById('appData').innerHTML);
      // let {data} = await axios.get(`${CONST.API_PATH_AUTH}/session`);
      // this.session = data.data;
    },
    /**
     * fetch current loggedin user data
     * @returns {Promise<void>}
     */
    async fetchUser() {
      let {data} = await axios.get(`${CONST.API_PATH_PROFILE}/user`);
      this.user = data.data;
    },
    fetchRegisterData(){
      return axios.get(`${CONST.API_PATH_AUTH}/register`);
    },
    fetchResetPasswordData(){
      return axios.get(`${CONST.API_PATH_AUTH}/password/reset`);
    },
    /**
     * Login user
     * @param {Object} payload payload
     * @param {String} payload.email user email
     * @param {String} payload.password user password
     * @returns {Promise<void>}
     */
    async loginUser(payload) {
      return await axios.post(`${CONST.API_PATH_AUTH}/login`, payload);

    },
    async registerUser(payload) {
      return await axios.post(`${CONST.API_PATH_AUTH}/register`, payload);
    },
    /**
     * Send password reset link to specified email
     * @param {Object} payload payload
     * @param {String} payload.email user email
     * @returns {Promise<void>}
     */
    async resetPasswordLink(payload) {
      await axios.post(`${CONST.API_PATH_AUTH}/password/reset/link`, payload);
    },
    /**
     * Reset user password
     * @param {Object} payload payload
     * @param {String} payload.email email
     * @param {String} payload.token password reset link token
     * @param {String} payload.password password
     * @param {String} payload.password_confirmation password confirmation
     * @returns {Promise<void>}
     */
    async resetPassword(payload) {
      await axios.post(`${CONST.API_PATH_AUTH}/password/reset`, payload);
    },
    async setTwoFaNumber(payload) {
      await axios.post(`${CONST.API_PATH_AUTH}/two_fa/setup`, payload);
    },
    async getTwoFaCode() {
      await axios.get(`${CONST.API_PATH_AUTH}/two_fa/send`);
    },
    /**
     * Verify two factor authentication code
     * @param {Object} payload payload
     * @param {String} payload.code code from sms
     * @param {String} payload.remember password reset link token
     * @returns {Promise<void>}
     */
    async verifyTwoFaCode(payload) {
      return await axios.post(`${CONST.API_PATH_AUTH}/two_fa/code`, payload);
    },
    async verifyTwoFaSetup(payload) {
      return await axios.post(`${CONST.API_PATH_AUTH}/two_fa/verify`, payload);
    },
    async getPasscodeCode() {
      await axios.get(`${CONST.API_PATH_AUTH}/passcode/send`);
    },
    /**
     * Verify passcode authentication code
     * @param {Object} payload payload
     * @param {String} payload.code code from email
     * @param {Boolean} payload.mail_subscription subscribe to nowhere mails
     * @returns {Promise<void>}
     */
    async verifyPasscodeCode(payload) {
      return await axios.post(`${CONST.API_PATH_AUTH}/passcode/verify`, payload);
    },

    /**
     * fetch current user available accounts
     * @returns {Promise<void>}
     */
    async fetchAccountsList() {
      return axios.get(`${CONST.API_PATH_AUTH}/register`);
    },
    /**
     * Send account name to server
     * @param {String} payload.account_name account name
     * @returns {Promise<void>}
     */
    async chooseAccount(payload) {
      return await axios.post(`${CONST.API_PATH_AUTH}/passcode/verify`, payload);
    },
    /**
     * Send account links to specified email
     * @param {Object} payload payload
     * @param {String} payload.email user email
     * @returns {Promise<void>}
     */
    async forgotAccount(payload) {
      await axios.post(`${CONST.API_PATH_AUTH}/acccount/forgot`, payload);
    },
  },
});
