<template>
  <form novalidate autocomplete="off" @submit.prevent="onSubmit">
    <p>Please enter your account url to continue.</p>

    <p v-if="$externalResults.form?.global" class="text-danger">
      {{ $externalResults.form?.global }}
    </p>

    <div class="account-name-group form-group form-group-modern border-bottom" :class="{'is-invalid': v$.form.account_name.$error}">
      <div class="input-group">
        <input v-model="form.account_name" class="form-control form-control-modern border-0" :class="{'is-invalid': v$.form.account_name.$error}" placeholder="your-account-url" type="text" tabindex="1" autocomplete="">
        <span class="input-group-text border-0 text-black ps-0">
          <div class="extra-text"><span class="text">.now-here.com</span></div>
        </span>
      </div>
    </div>
    <transition name="fadeDown">
      <span v-if="v$.form.account_name.$error" class="invalid-feedback">{{ v$.form.account_name.$errors[0].$message }}</span>
    </transition>

    <div class="form-controls text-center mt-10 mb-4 d-grid">
      <button type="submit" class="submit-btn btn btn-outline-primary btn-lg" tabindex="1" :disabled="state.processing">
        <span v-if="!state.processing">Continue</span>
        <span v-else>Processing <img v-svg-inline class="isvg isvg-spinner" src="@root/img/svg/spinner.svg"></span>
      </button>
    </div>

    <p class="text-center"><router-link class="link-black" :to="{name: 'account-forgot'}" tabindex="1">I forgot my account details</router-link></p>
  </form>
</template>

<script setup>
import { email, required } from "@root/js/vuejs/utils/vuelidate";
import useVuelidate from '@vuelidate/core';
import * as validators from '@vuelidate/validators';
import { reactive, ref } from 'vue';
import {onBeforeRouteUpdate} from 'vue-router';

import {helpers, maxLength} from '../../../../../../../resources/assets/js/vuejs/utils/vuelidate.js';
import { useMainStore } from '../../stores/main.js';

const accountName = validators.helpers.withMessage("This field can only contain letters and numbers.", helpers.regex(/^([0-9a-zA-Z-]+)$/));

const store = useMainStore();

// State
const state = reactive({
    processing: null
  }),
  form = reactive({
    account_name: ""
  });

// Hooks
onBeforeRouteUpdate(() => {
  state.processing = false;
});

// Validation
const $externalResults = ref({});
const rules = {
  form: {
    account_name: {required, maxLength: maxLength(140), accountName},
  },
};

const v$ = useVuelidate(rules, { form }, { $externalResults, $autoDirty: true });

async function onSubmit() {
  if(state.processing || !await v$.value.$validate()) return; // invalid or processing form

  state.processing = true;

  try {
    let {data} = await store.chooseAccount({
      ...form
    });
    window.location.href = data?.data?.redirect_url;
  } catch(err) {
    if(err.response?.data?.errors){
      $externalResults.value = {
        form: err.response.data.errors
      };
    }
    state.processing = false;
  }
}
</script>
