<template>
  <form novalidate @submit.prevent="onSubmit">
    <p>Please enter the code we sent to *********{{ phone }}. If you have not received it within a few minutes you can <a class="link-black" :class="{disabled: state.processing}" href="#" tabindex="2" @click.prevent="resendCode">resend the code</a>.</p>

    <p v-if="$externalResults.form?.global" class="text-danger">
      {{ $externalResults.form?.global }}
    </p>

    <div class="form-group mb-6">
      <input v-model="form.code" class="form-control form-control-modern" :class="{'is-invalid': v$.form.code.$error}" placeholder="Enter code..." type="text" tabindex="1">
      <transition name="fadeDown">
        <span v-if="v$.form.code.$error" class="invalid-feedback">{{ v$.form.code.$errors[0].$message }}</span>
      </transition>
    </div>

    <div class="form-group">
      <div class="form-check">
        <input id="remember" v-model="form.remember" type="checkbox" class="form-check-input" tabindex="1">
        <label class="form-check-label fs-sm " for="remember">Remember my code for two weeks?</label>
      </div>
    </div>

    <div class="form-controls text-center mt-10 mb-4 d-grid">
      <button type="submit" class="submit-btn btn btn-outline-primary btn-lg" tabindex="1" :disabled="state.processing">
        <span v-if="!state.processing">Log in</span>
        <span v-else>Processing</span>
      </button>
    </div>

    <transition name="fade">
      <div v-if="state.processing" class="component-loader">
        <img v-svg-inline class="isvg isvg-spinner" src="@root/img/svg/spinner.svg">
      </div>
    </transition>
  </form>
</template>

<script setup>
import { externalServerValidation, required } from "@root/js/vuejs/utils/vuelidate";
import useVuelidate from '@vuelidate/core';
import { onBeforeUnmount, onMounted, reactive, ref } from 'vue';
import {useRouter} from 'vue-router';
import {onBeforeRouteUpdate} from 'vue-router';

import { useMainStore } from '../../stores/main.js';

const store = useMainStore();
const router = useRouter();


const props = defineProps({
  phone: String
});

// State
const state = reactive({
    processing: null
  }),
  form = reactive({
    code: "",
    remember: false
  });

// Hooks
onBeforeRouteUpdate(() => {
  state.loading = false;
});


// Validation
const $externalResults = ref({});
const rules = {
    form: {
      code: {required}
    },
  };

const v$ = useVuelidate(rules, { form }, { $externalResults, $autoDirty: true });

resendCode();

async function resendCode() {
  if(state.processing) return; // invalid or processing form
  state.processing = true;

  try {
    await store.getTwoFaCode();
    state.processing = false;
    $externalResults.value = {};
  } catch(err) {
    if(err.response?.data?.errors){
      $externalResults.value = {
        form: err.response.data.errors
      };
    }
    state.processing = false;
  }
}

async function onSubmit() {
  if(state.processing || !await v$.value.$validate()) return; // invalid or processing form
  state.processing = true;

  try {
    let {data} = await store.verifyTwoFaCode({
      ...form
    });
    window.location.href = data?.data?.redirect_url;
  } catch(err) {
    if(err.response?.data?.errors){
      $externalResults.value = {
        form: err.response.data.errors
      };
    }
    state.processing = false;
  }
}
</script>
