<template>
  <form novalidate @submit.prevent="onSubmit">
    <p v-if="$externalResults.form?.global" class="text-danger">
      {{ $externalResults.form?.global }}
    </p>

    <p>
      <span v-if="store.isMicroskills">Micro-Skills is a <strong class="nowhere">nowhere</strong> app.</span>
      Please sign up to continue. If you already have an account, please
      <router-link class="link-black" :to="{name: 'login'}">log in</router-link>.
    </p>
    <transition name="fadeUp">
      <div v-if="state.readonlyEmailError" class="text-danger">
        <p v-if="store.isBixe">
          Email cannot be edited. Your invite link will only work when using this email. If you would like to use an alternative email, please contact <a class="link-black" href="mailto:support@now-here.com" target="_blank">support@now-here.com</a>.
        </p>
        <p v-else>Sorry, your email address cannot be edited. To claim this link using an alternative email, please contact <a class="link-black" href="mailto:support@now-here.com" target="_blank">support@now-here.com</a>.</p>
      </div>
    </transition>

    <div class="form-group form-group-modern">
      <input v-model="form.first_name" class="form-control form-control-modern" :class="{'is-invalid': v$.form.first_name.$error}" placeholder="First name..." type="text" tabindex="1">
      <transition name="fadeDown">
        <span v-if="v$.form.first_name.$error" class="invalid-feedback">{{ v$.form.first_name.$errors[0].$message }}</span>
      </transition>
    </div>

    <div class="form-group form-group-modern">
      <input v-model="form.last_name" class="form-control form-control-modern" :class="{'is-invalid': v$.form.last_name.$error}" placeholder="Last name..." type="text" tabindex="2">
      <transition name="fadeDown">
        <span v-if="v$.form.last_name.$error" class="invalid-feedback">{{ v$.form.last_name.$errors[0].$message }}</span>
      </transition>
    </div>

    <div class="form-group form-group-modern">
      <input v-model="form.email" class="form-control form-control-modern" :class="{'is-invalid': v$.form.email.$error}" placeholder="Email..." type="email" tabindex="3" :readonly="form.email_readonly" v-on="form.email_readonly ? {click: onReadonlyEmailClick} : {}">
      <transition name="fadeDown">
        <span v-if="v$.form.email.$error" class="invalid-feedback">{{ v$.form.email.$errors[0].$message }}</span>
      </transition>
    </div>

    <div class="form-group form-group-modern">
      <input v-model="form.password" class="form-control form-control-modern" :class="{'is-invalid': v$.form.password.$error}" placeholder="Password..." type="password" tabindex="4">
      <transition name="fadeDown">
        <span v-if="v$.form.password.$error" class="invalid-feedback">{{ v$.form.password.$errors[0].$message }}</span>
      </transition>
    </div>

    <transition name="fade">
      <div v-if="state.showSubscribe || form.subscribed !== null" class="form-group subscribe-block">
        <p class="text-size-sm">Would you like to hear from us occasionally?</p>

        <div class="d-grid">
          <div class="btn-group" role="group" :class="{ 'animate__animated animate__shakeX': state.animateSubscribe }">
            <input id="btnradio1" v-model="form.subscribed" :value="false" type="radio" class="btn-check" name="btnradio" autocomplete="off">
            <label class="btn btn-outline-primary" for="btnradio1">No</label>

            <input id="btnradio2" v-model="form.subscribed" :value="true" type="radio" class="btn-check" name="btnradio" autocomplete="off">
            <label class="btn btn-outline-primary" for="btnradio2">Yes</label>
          </div>
        </div>

        <transition name="fadeDown" mode="out-in">
          <span v-if="form.subscribed === false" class="invalid-feedback text-size-sm">We only send emails when we have something interesting to share. Want to change your mind?</span>
          <span v-else-if="form.subscribed === true" class="valid-feedback text-body text-size-sm">Thank you. We promise to respect your inbox.</span>
        </transition>
      </div>
    </transition>

    <div class="form-controls text-center mt-10 mb-4 d-grid">
      <button type="submit" class="submit-btn btn btn-outline-primary btn-lg" tabindex="4" :disabled="state.processing">
        <span v-if="!state.processing">Sign up</span>
        <span v-else>Processing <img v-svg-inline class="isvg isvg-spinner" src="@root/img/svg/spinner.svg"></span>
      </button>
    </div>

    <p class="text-center text-size-sm">By clicking 'Sign up' you agree to our<br>
      <a class="link-black" :href="`${CONST.NOWHERE_URL}/legals/tos-applications`" target="_blank">Terms of Service</a>,
      <a class="link-black" :href="`${CONST.NOWHERE_URL}/legals/privacy`" target="_blank">Privacy Policy</a> &
      <a class="link-black" :href="`${CONST.NOWHERE_URL}/legals/eula`" target="_blank">EULA</a>
    </p>
  </form>
</template>

<script setup>
import { email, maxLength, required } from "@root/js/vuejs/utils/vuelidate";
import useVuelidate from '@vuelidate/core';
import { computed, onBeforeUnmount, reactive, ref, watch } from 'vue';
import {onBeforeRouteUpdate} from 'vue-router';

import CONST from '../../common/constants';
import { useMainStore } from '../../stores/main.js';

const store = useMainStore();

const state = reactive({
    loading: null,
    processing: null,
    readonlyEmailError: null,
    animateSubscribe: false,
    showSubscribe: null
  }),
  form = reactive({
    first_name: "",
    last_name: "",
    email: "",
    email_readonly: null,
    password: "",
    subscribed: null
  });

// Watchers
watch(
  () => form.password,
  (password) => {
    if(password) state.showSubscribe = true;
  }
)

// Hooks
onBeforeRouteUpdate(() => {
  state.loading = false;
});

// Validation
const $externalResults = ref({});
const rules = {
    form: {
      first_name: {required},
      last_name: {required},
      email: {required, email},
      password: {required}
    },
  };

const v$ = useVuelidate(rules, { form }, { $externalResults, $autoDirty: true });

fetchInitialData();

async function fetchInitialData(){
  store.loading = true;

  try {
    let {data} = await store.fetchRegisterData();
    form.email = data?.data?.email || form.email;
    form.email_readonly = data?.data?.email_readonly;
    store.loading = false;
  } catch (err){
    store.loading = false;
  }
}

// TODO-Max: add this logic too
function onReadonlyEmailClick(){
  state.readonlyEmailError = true;
}

async function onSubmit() {
  // In case of unclicked Subscribe button on register page - shake btns only
  if(form.subscribed === null && state.showSubscribe){
    state.animateSubscribe = true;
    form.subscribed = false;
    return false;
  }

  if(state.processing || !await v$.value.$validate()) return; // invalid or processing form

  state.processing = true;
  state.readonlyEmailError = false;

  try {
    let {data} = await store.registerUser({
      ...form
    });
    window.location.href = data?.data?.redirect_url;
  } catch(err) {
    if(err.response?.data?.errors){
      $externalResults.value = {
        form: err.response.data.errors
      };
    }
    state.processing = false;
  }
}
</script>
