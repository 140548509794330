<template>
  <form novalidate @submit.prevent="onSubmit">
    <p v-if="state.success" class="text-success">We have sent you an email, please check your mail and follow the instructions...</p>
    <p v-else>To reset your password, please enter the email address you signed up with below...</p>

    <p v-if="$externalResults.form?.global" class="text-danger">
      {{ $externalResults.form?.global }}
    </p>

    <div class="form-group form-group-modern">
      <input v-model="form.email" class="form-control form-control-modern" :class="{'is-invalid': v$.form.email.$error}" placeholder="Email..." type="email" tabindex="1">
      <transition name="fadeDown">
        <span v-if="v$.form.email.$error" class="invalid-feedback">{{ v$.form.email.$errors[0].$message }}</span>
      </transition>
    </div>

    <div class="form-controls text-center mt-10 mb-4 d-grid">
      <button type="submit" class="submit-btn btn btn-outline-primary btn-lg" tabindex="4" :disabled="state.processing">
        <span v-if="!state.processing">
          <span v-if="state.success">Send Again</span>
          <span v-else>Submit</span>
        </span>
        <span v-else>Processing <img v-svg-inline class="isvg isvg-spinner" src="@root/img/svg/spinner.svg"></span>
      </button>
    </div>
  </form>
</template>

<script setup>
import { email, maxLength, required } from "@root/js/vuejs/utils/vuelidate";
import useVuelidate from '@vuelidate/core';
import { onBeforeUnmount, onMounted, reactive, ref } from 'vue';

import { useMainStore } from '../../stores/main.js';

const store = useMainStore();

const state = reactive({
    processing: null,
    success: null
  }),
  form = reactive({
    email: "",
  });

// Validation
const $externalResults = ref({});
const rules = {
    form: {
      email: {required, email}
    },
  };

const v$ = useVuelidate(rules, { form }, { $externalResults, $autoDirty: true });

async function onSubmit() {
  if(state.processing || !await v$.value.$validate()) return; // invalid or processing form

  state.processing = true;

  try {
    await store.resetPasswordLink({
      ...form
    });
    state.success = true;
    state.processing = false;
  } catch(err) {
    if(err.response?.data?.errors){
      $externalResults.value = {
        form: err.response.data.errors
      };
    }
    state.processing = false;
  }
}
</script>
