<template>
  <ul class="accounts-list list-unstyled">
    <li v-for="account in state.accounts" class="account-link-wrapper">
      <a :href="`https://${account.domain}`" class="text-decoration-none">
        <div class="logo">
          <img class="img-responsive" :src="account.logo_url" :alt="account.name">
          <!--         v-if="account.logo_url" <img v-else class="img-responsive" src="/auth/img/favicon/apple-touch-icon.png" :alt="account.name">-->
        </div>
        <strong>{{ account.name }}</strong><br>
        {{ account.email }} <br>
        <span class="status text-size-normal">
          <span v-if="account.login">Logged in</span>
          <span v-else>Logged out</span>
        </span>
      </a>
    </li>
  </ul>

  <div class="account-link-wrapper">
    <router-link class="add-account-link text-decoration-none" :to="{name: 'account-name'}">
      <div class="logo" />
      Add another account
    </router-link>
  </div>
</template>

<script setup>
import { email, maxLength, required } from "@root/js/vuejs/utils/vuelidate";
import useVuelidate from '@vuelidate/core';
import { computed, onBeforeUnmount, reactive, ref, watch } from 'vue';

import CONST from '../../common/constants';
import { useMainStore } from '../../stores/main.js';

const store = useMainStore();

const state = reactive({
    loading: null,
    accounts: null
  });

fetchInitialData();

async function fetchInitialData(){
  state.loading = true;

  try {
    let {data} = await store.fetchAccountsList();
    state.accounts = data?.data?.accounts;
    // state.accounts = [
    //   {
    //     name: "Adama",
    //     email: "email@email.com",
    //     login: false,
    //     logo_url: "https://apps.local.now-here.info/img/adama/favicon/apple-touch-icon.png"
    //   }
    // ];
    state.loading = false;
  } catch (err){
    state.loading = false;
  }
}
</script>
