<template>
  <div class="auth-form-wrapper container">
    <div v-if="store.isBixe" class="text-center">
      <a class="bixe-logo d-inline-block mb-9">
        <img v-svg-inline class="isvg" src="../../img/bixe-lg-beta.svg">
      </a>
    </div>

    <a v-else class="nowhere-logo" href="https://now-here.com/">
      <img v-svg-inline class="isvg" src="../../img/nowhere.svg">
    </a>
    <slot />
  </div>
</template>
<script setup>
import { computed, reactive } from 'vue';
import {RouterView, useRoute} from 'vue-router';

import { useMainStore } from '../stores/main.js';

const route = useRoute();

// Store
const store = useMainStore();
</script>
