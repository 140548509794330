import * as vuelidate from '@vuelidate/validators';
import * as validators from '@vuelidate/validators';
import {
  isValidPhoneNumber
} from 'libphonenumber-js'


const messages = {
  "alpha": "This field can only contain letters",
  "email": "Please enter a valid email address",
  "required": "Please fill out this field",
  "regex": "{field} contains invalid characters",
  "maxLength": "Please enter less than 0:{length} characters",
  "minLength": "{_field_} must be at least 0:{length} letter(s).",
  "unique": "This {field} has already been added",
  "url": "Please enter a valid URL."
};

// wrap each validator.
const between = validators.between;
const sameAs = validators.sameAs;
const numeric = validators.numeric;
const email = validators.helpers.withMessage(messages.email, validators.email);
const required = validators.helpers.withMessage(messages.required, validators.required);
const maxLength = function(){
  return validators.helpers.withMessage(
    ({$params}) => `Please enter less than ${$params.max} characters`,
    validators.maxLength(...arguments),
  );
};
const minLength = function(){
  return validators.helpers.withMessage(
    ({$params}) => `Please enter at least ${$params.min} character(s)`,
    validators.minLength(...arguments),
  );
};

// If value is unique in provided group
function notIn$(group, key){
  return (value) => {
    const found = group.filter((item) => {
      if(key) {
        return item[key] === value;
      }
      return item === value;
    });
    console.log(found.length !== 0);
    return found.length === 0;
  };
}
const notIn = function(){
  return validators.helpers.withMessage(`This email has already been added`,
    notIn$(...arguments),
  );
};

const helpers = validators.helpers;
const alpha = validators.helpers.withMessage(messages.alpha, validators.alpha);

const firstLastNameValidator = helpers.withMessage('This field can only contain letters', helpers.regex(/^[^0-9_!¡÷?¿/\\+=@#$%ˆ&*(){}|~<>;:^[\]]+$/));

const url = validators.helpers.withMessage(messages.url, validators.url);

const sameAsPassword = function(){
  return validators.helpers.withMessage(`Your passwords do not match`, validators.sameAs(...arguments));
};

const strongPassword = validators.helpers.withMessage("Sorry, your password must contain one number, one uppercase letter and have at least eight characters.", helpers.regex(/(?=.*\d)(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}/));

const phone = vuelidate.helpers.withMessage("Please enter a valid phone number in international format +1234567890", (value) =>
  // console.log(value, !vuelidate.helpers.req(value));
  !vuelidate.helpers.req(value) || isValidPhoneNumber(value)
);

const externalServerValidation = () => true;

export {
  alpha,
  email,
  required,
  maxLength,
  minLength,
  notIn,
  between,
  url,
  numeric,
  messages,
  helpers,
  externalServerValidation,
  firstLastNameValidator,
  strongPassword,
  sameAsPassword,
  sameAs,
  phone
};
