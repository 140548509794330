<template>
  <div class="error-block d-flex justify-content-center flex-column pt-12">
    <div class="container-md">
      <h1 class="title">AAGGGGHH!</h1>
      <div class="text fw-light">
        <p>Sorry the page you are looking for <br>is <span class="nowhere">nowhere</span> to be&nbsp;found.</p>

        <a class="link-black" href="/">Go to homepage</a>
      </div>
    </div>
  </div>
</template>

<script>
</script>


