import Noty from 'noty';

Noty.overrideDefaults({
  timeout: 3000,
  progressBar: false,
  closeWith: ['button','click'],
  layout: "topRight",
  theme: 'bootstrap-v4'
});

const notification = {
  error: (message, timeout) => {
    message = message || 'Oops, something went wrong!';
    return new Noty({
      type: 'error',
      text: message,
      timeout: timeout ? 3000 : false
    }).show();
  },
  success: (message) => new Noty({
      type: 'success',
      text: message,
    }).show()
};

export default notification;
