<template>
  <form novalidate @submit.prevent="onSubmit">
    <p>Thanks, please check your SMS messages and enter the code we sent to {{ store?.twoFa?.phone }}.</p>

    <p v-if="$externalResults.form?.global" class="text-danger">
      {{ $externalResults.form?.global }}
    </p>

    <div class="form-group form-group-modern">
      <input v-model="form.code" class="form-control form-control-modern" :class="{'is-invalid': v$.form.code.$error}" placeholder="Enter verification code..." type="text" tabindex="1">
      <transition name="fadeDown">
        <span v-if="v$.form.code.$error" class="invalid-feedback">{{ v$.form.code.$errors[0].$message }}</span>
      </transition>
    </div>

    <div class="form-controls text-center mt-10 mb-4 d-grid">
      <button type="submit" class="submit-btn btn btn-outline-primary btn-lg" tabindex="4">
        <span v-if="!state.processing">Submit</span>
        <span v-else>Processing <img v-svg-inline class="isvg isvg-spinner" src="@root/img/svg/spinner.svg"></span>
      </button>
    </div>
  </form>
</template>

<script setup>
import { required } from "@root/js/vuejs/utils/vuelidate";
import useVuelidate from '@vuelidate/core';
import { reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import {onBeforeRouteUpdate} from 'vue-router';

import { useMainStore } from '../../stores/main.js';

const router = useRouter(),
  store = useMainStore();

// State
const state = reactive({
    loading: null
  }),
  form = reactive({
    code: "",
  });

// Hooks
onBeforeRouteUpdate(() => {
  state.loading = false;
});

// Validation
const $externalResults = ref({});
const rules = {
  form: {
    code: { required }
  },
};

const v$ = useVuelidate(rules, { form }, { $externalResults, $autoDirty: true });

// Check if phone number is set
if(!store?.twoFa?.phone) {
  router.push({name: 'two-fa-setup'});
}

async function onSubmit() {
  if(state.processing || !await v$.value.$validate()) return; // invalid or processing form

  state.processing = true;

  try {
    let {data} = await store.verifyTwoFaSetup({
      ...form
    });
    window.location.href = data?.data?.redirect_url;
  } catch(err) {
    if(err.response?.data?.errors){
      $externalResults.value = {
        form: err.response.data.errors
      };
    }
    state.processing = false;
  }
}
</script>
