import '@root/js/vuejs/common/tracker';
import axios from 'axios';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import VueSvgInlinePlugin from "vue-svg-inline-plugin";
import VueClickAway from 'vue3-click-away';
import '../sass/app.scss';

import App from './App.vue';
import './common/axios';
import router from './router';

const pinia = createPinia();
const app = createApp(App);

axios.get(`/auth/sanctum/csrf-cookie`)
  .then(() => {
    app.use(pinia)
      .use(router)
      .use(VueClickAway)
      .use(VueSvgInlinePlugin);

    app.mount('#app');
  })
  .catch((err) => {
    console.error(err);
  });

if(import.meta.env.MODE === "development") {
  window.addEventListener("beforeunload", function () {
    // debugger;
  }, false);
}
