<template>
  <form novalidate @submit.prevent="onSubmit">
    <p v-if="selfSetup">Set up Two Factor Authentication to add an extra layer of security to your account. Enter your mobile number to get started.</p>
    <p v-else>To increase the security of your account, the administrator has required that you turn on Two Factor Authentication. Enter your mobile number to get started.</p>

    <p v-if="$externalResults.form?.global" class="text-danger">
      {{ $externalResults.form?.global }}
    </p>

    <div class="form-group form-group-modern">
      <vue-tel-input v-model="form.phone" class="form-control form-control-modern" :class="{'is-invalid': v$.form.phone.$error}" tabindex="1" v-bind="vueTelInputParams" />
      <!--      @phone:invalid="togglePhoneError($event, 'add')" @phone:valid="togglePhoneError($event, 'remove')"-->
      <transition name="fadeDown">
        <span v-if="v$.form.phone.$error" class="invalid-feedback">{{ v$.form.phone.$errors[0].$message }}</span>
      </transition>
    </div>

    <div class="form-controls text-center mt-10 mb-4 d-grid">
      <button type="submit" class="submit-btn btn btn-outline-primary btn-lg" tabindex="4" :disabled="state.processing">
        <span v-if="!state.processing">Start</span>
        <span v-else>Processing <img v-svg-inline class="isvg isvg-spinner" src="@root/img/svg/spinner.svg"></span>
      </button>
    </div>
  </form>
</template>

<script setup>
import { phone, required } from "@root/js/vuejs/utils/vuelidate";
import useVuelidate from '@vuelidate/core';
import { computed, onBeforeUnmount, onMounted, reactive, ref } from 'vue';
import {useRoute, useRouter} from 'vue-router';
import { VueTelInput } from 'vue-tel-input';

import { useMainStore } from '../../stores/main.js';

const router = useRouter(),
  store = useMainStore(),
  route = useRoute();

// State
const state = reactive({
    loading: null,
    success: null
  }),
  form = reactive({
    phone: "",
  });

// Computed
const selfSetup = computed(() => route.query?.enforced === '0' || !route.query?.enforced);

const vueTelInputParams = {
  disabledFetchingCountry: true,
  defaultCountry: 'gb',
  validCharactersOnly: true,
  inputOptions: {
    placeholder: 'Mobile number...',
    showDialCode: true
  }
}

// Validation
const $externalResults = ref({});
const rules = {
    form: {
      phone: { required, phone}
    },
  };

const v$ = useVuelidate(rules, { form }, { $externalResults, $autoDirty: true });

async function onSubmit() {
  if(state.processing || !await v$.value.$validate()) return; // invalid or processing form

  state.processing = true;

  try {
    // remove all spaces from phone
    let phone = form.phone.replace(/\s/g, '');
    await store.setTwoFaNumber({
      phone: phone
    });
    store.twoFa = { 'phone': form.phone };
    console.log("phone ", form.phone)
    state.processing = false;
    router.push({ name: "two-fa-verify"});
  } catch(err) {
    if(err.response?.data?.errors){
      $externalResults.value = {
        form: err.response.data.errors
      };
    }
    state.processing = false;
  }
}
</script>
