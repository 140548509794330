<template>
  <div v-if="expired" class="alert alert-warning">
    You have been automatically logged out due to inactivity.<br>
    Please login to continue
  </div>

  <p v-if="$externalResults.form?.global" class="text-danger">
    {{ $externalResults.form?.global }}
  </p>

  <form novalidate @submit.prevent="onSubmit">
    <div class="form-group form-group-modern">
      <input v-model="form.email" class="form-control form-control-modern" :class="{'is-invalid': v$.form.email.$error}" placeholder="Email..." type="email" tabindex="1">
      <transition name="fadeDown">
        <span v-if="v$.form.email.$error" class="invalid-feedback">{{ v$.form.email.$errors[0].$message }}</span>
      </transition>
    </div>
    <div class="form-group form-group-modern" :class="{'is-invalid': v$.form.password.$error}">
      <div class="input-group">
        <input v-model="form.password" class="form-control form-control-modern border-0" :class="{'is-invalid': v$.form.password.$error}" placeholder="Password..." type="password" tabindex="2">
        <span class="input-group-text border-0">
          <a v-if="store.isElectronApp" href="#" class="link-black" tabindex="3" @click.prevent="openExternal(`${window.location}/auth/password/reset`)"><span class="text fs-base">Forgot?</span></a>
          <router-link v-else class="link-black" :to="{name: 'password-forgot'}" tabindex="3"><span class="text fs-base">Forgot?</span></router-link>
        </span>
      </div>

      <transition name="fadeDown">
        <span v-if="v$.form.password.$error" class="invalid-feedback">{{ v$.form.password.$errors[0].$message }}</span>
      </transition>
    </div>

    <div class="form-controls text-center mt-10 mb-4 d-grid">
      <button type="submit" class="submit-btn btn btn-outline-primary btn-lg" tabindex="4" :disabled="state.processing">
        <span v-if="!state.processing">Log in</span>
        <span v-else>Processing <img v-svg-inline class="isvg isvg-spinner" src="@root/img/svg/spinner.svg"></span>
      </button>
    </div>

    <p class="text-center">If you don’t have an account,<br> please <router-link class="link-black" :to="{name: 'register'}" tabindex="5">sign up</router-link></p>
  </form>
</template>

<script setup>
import { email, required } from "@root/js/vuejs/utils/vuelidate";
import useVuelidate from '@vuelidate/core';
import { reactive, ref } from 'vue';
import {onBeforeRouteUpdate} from 'vue-router';

import { useMainStore } from '../../stores/main.js';

const store = useMainStore();

const props = defineProps({
  expired: Boolean
});

// State
const state = reactive({
    processing: null
  }),
  form = reactive({
    email: "",
    password: ""
  });

// Hooks
onBeforeRouteUpdate(() => {
  state.loading = false;
});

// Validation
const $externalResults = ref({});
const rules = {
    form: {
      email: {required, email},
      password: {required}
    },
  };

const v$ = useVuelidate(rules, { form }, { $externalResults, $autoDirty: true });

function openExternal(url) {
  window.ipcApi.send({name: 'openExternal', url: url, closeSelf: false});
}
async function onSubmit() {
  if(state.processing || !await v$.value.$validate()) return; // invalid or processing form

  state.processing = true;

  try {
    let {data} = await store.loginUser({
      ...form
    });
    window.location.href = data?.data?.redirect_url;
  } catch(err) {
    if(err.response?.data?.errors){
      $externalResults.value = {
        form: err.response.data.errors
      };
    }
    state.processing = false;
  }
}
</script>
